<template>
    <div>
      <b-row>
        <b-col cols="2" v-for="stranger in strangers"
            :key="stranger.id">
            <b-card>
                <img style="width:100%" :src="'data:image/jpeg;base64,'+stranger.image" />
                <span style="font-size:12px;">{{ stranger.created_at }}</span>
                <b-button block size="sm" class="mt-50" @click="delete_stranger(stranger.id)" variant="outline-danger">{{ $t('Delete') }}</b-button>
            </b-card>
        </b-col>
        
      </b-row>
     

    </div>
  </template>
  
  <script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  
  import vSelect from 'vue-select'
  import Ripple from 'vue-ripple-directive'
  import flatPickr from 'vue-flatpickr-component'
  import moment from 'moment'
  import {
    BTable, BAvatar, BCard, BCardBody, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BInputGroupPrepend} from 'bootstrap-vue'
  export default {
    components: {
      moment,
      BCard,
      flatPickr,
      BTable,
      BAvatar,
      BBadge,
      BRow,
      BCol,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BInputGroupPrepend,
      BButton,
      BPagination,
      BCardCode,
      BTable,
      BButton,
      BFormGroup,
      BAvatar,
      BCardBody,
      BBadge,
      vSelect,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        rangeDate: null,
        strangers: null,
        test_string : null,
        headerTitle: ['Date','DAILY TOTAL'],
        headerVal: ['date','total'],
      }
    },
    watch: {
      rangeDate : {
        handler(newValue, oldValue) {
          this.get_data()
        },
      },
      currentPage: {
        handler(newValue, oldValue) {
          this.get_data()
        },
      },
      searchString: {
        handler(newValue, oldValue) {
          // console.log("search")
          this.searchString = newValue.toUpperCase()
          this.handleSearch()
        },
      },
      perPage: {
        handler(newValue, oldValue) {
          if (newValue < 9) {
            this.perPage = 10
          }
          // console.log("search")
          this.handleSearch()
        },
      },
    },
    created() {
      this.get_data()
    },
    methods: {
      delete_stranger(id) {
        this.$http.post(process.env.VUE_APP_API_BASE+'/stranger/' + id + '/delete/')
          .then(res => {
            this.get_data()
          })
      },
     
      get_data() {
        var splitted = []
        if (this.rangeDate === null) {
          splitted[0] = null
          splitted[1] = null
          this.rangeDate = moment().subtract(7, 'days').format('YYYY-MM-DD') + " to " + moment().format("YYYY-MM-DD")
        } else {
          splitted = this.rangeDate.split(" to ");
        }
        this.$http.get(process.env.VUE_APP_API_BASE+`/report/stranger/`+this.$store.getters['center/currentCenterId'] + `/`)
          .then(res => {
            this.strangers = res.data
              console.log( res.data )
          })
      },
      handleChangePage() {
  
      //  this.get_data()
      },
      onRowSelected(items) {
        this.selected = items
        console.log(items)
        this.$router.push(`/apps/member/edit/${items[0].member_id}/`)
      },
      selectAllRows() {
        this.$refs.selectableTable.selectAllRows()
      },
      clearSelected() {
        this.$refs.selectableTable.clearSelected()
      },
      selectThirdRow() {
        // Rows are indexed from 0, so the third row is index 2
        this.$refs.selectableTable.selectRow(2)
      },
      unselectThirdRow() {
        // Rows are indexed from 0, so the third row is index 2
        this.$refs.selectableTable.unselectRow(2)
      },
  
      handleSearch() {
        // let _print = `The user searched for: ${searching}\n`
        // this.$refs.pre.appendChild(document.createTextNode(_print))
      // this.searchString = searching
      // this.currentx = 1
      // this.get_data()
        this.searchTimeOut()
      },
      searchTimeOut() {
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }
        this.timer = setTimeout(() => {
          // your code
          this.currentPage = 1
          this.get_data()
        }, 500)
      },
    },
  }
  </script>
  <style lang="scss">
  
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>
  <style lang="scss">
  .b-table-selectable{
    .feather{
      font-size: 1.3rem;
    }
  }
  
  </style>
  